import { Component } from '@angular/core';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrl: '../../../shared/style.css'
})
export class ChangepasswordComponent {

}
