import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrl: './thankyou.component.css'
})
export class ThankyouComponent {
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    private router : Router,
    public dialogRef: MatDialogRef<ThankyouComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  onCloseClick(data: any) {
    this.dialogRef.close(data);
  }
  closeModal( $event: any ) {
    this.router.navigate([{outlets: {modal: null}}]);
    this.modalClose.next($event);
  }

}