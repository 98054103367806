export const jobTitles = [
    { "title": "Logistics Coordinator" },
    { "title": "Supply Chain Manager" },
    { "title": "Freight Forwarder" },
    { "title": "Customs Broker" },
    { "title": "Warehouse Manager" },
    { "title": "Transportation Manager" },
    { "title": "Import/Export Specialist" },
    { "title": "Air Freight Specialist" },
    { "title": "Sea Freight Coordinator" },
    { "title": "Project Cargo Manager" },
    { "title": "Operations Manager" },
    { "title": "Logistics Analyst" },
    { "title": "Distribution Manager" },
    { "title": "Inventory Control Specialist" },
    { "title": "Fleet Manager" },
    { "title": "Courier Services Manager" },
    { "title": "Cold Chain Manager" },
    { "title": "Hazardous Materials Specialist" },
    { "title": "E-commerce Logistics Coordinator" },
    { "title": "Aerospace Logistics Specialist" },
    { "title": "Retail Logistics Manager" },
    { "title": "Logistics Project Manager" },
    { "title": "Shipping Manager" },
    { "title": "Freight Operations Manager" },
    { "title": "Supply Chain Analyst" },
    { "title": "Inventory Manager" },
    { "title": "Warehouse Operations Supervisor" },
    { "title": "Logistics Consultant" },
    { "title": "Transport Planner" },
    { "title": "Export Compliance Specialist" },
    { "title": "Transportation Analyst" },
    { "title": "Logistics Sales Executive" },
    { "title": "Customs Compliance Manager" },
    { "title": "Customs Operations Manager" },
    { "title": "Customs Clearance Specialist" },
    { "title": "Customs Data Entry Clerk" },
    { "title": "Customs Auditor" },
    { "title": "Trade Compliance Specialist" },
    { "title": "Customs Broker Assistant" },
    { "title": "Customs Policy Analyst" },
    { "title": "Customs Analyst" },
    { "title": "Import Coordinator" },
    { "title": "Import Specialist" },
    { "title": "Import Compliance Officer" },
    { "title": "Import Documentation Specialist" },
    { "title": "Import Operations Manager" },
    { "title": "Export Coordinator" },
    { "title": "Export Specialist" },
    { "title": "Export Compliance Officer" },
    { "title": "Export Documentation Specialist" },
    { "title": "Export Operations Manager" },
    { "title": "Rail Freight Manager" },
    { "title": "Air Cargo Manager" },
    { "title": "Maritime Logistics Coordinator" },
    { "title": "Fleet Operations Coordinator" },
    { "title": "Logistics Quality Assurance Specialist" },
    { "title": "Supplier Relationship Manager" },
    { "title": "Risk Management Specialist" },
    { "title": "Demand Planner" },
    { "title": "Distribution Center Supervisor" },
    { "title": "Procurement Manager" },
    { "title": "Logistics Technology Specialist" },
    { "title": "Sourcing Specialist" },
    { "title": "Logistics Research Analyst" },
    { "title": "Reverse Logistics Coordinator" },
    { "title": "Process Improvement Manager" },
    { "title": "Transportation Safety Manager" },
    { "title": "Brokering Specialist" },
    { "title": "Last-Mile Delivery Manager" },
    { "title": "Marine Operations Manager" },
    { "title": "Energy Logistics Manager" },
    { "title": "Contract Logistics Manager" },
    { "title": "Transportation Compliance Officer" },
    { "title": "Global Logistics Manager" },
    { "title": "Operational Excellence Manager" },
    { "title": "Cross-Docking Coordinator" },
    { "title": "Material Handling Supervisor" },
    { "title": "Cook" },
    { "title": "Chef" },
    { "title": "Food Service Manager" },
    { "title": "Delivery Driver" },
    { "title": "Truck Driver" },
    { "title": "Bus Driver" },
    { "title": "Forklift Operator" },
    { "title": "IT Manager" },
    { "title": "Software Developer" },
    { "title": "Web Developer" },
    { "title": "App Developer" },
    { "title": "Frontend Developer" },
    { "title": "Backend Developer" },
    { "title": "Full Stack Developer" },
    { "title": "Mobile App Developer" },
    { "title": "DevOps Engineer" },
    { "title": "Systems Analyst" },
    { "title": "Network Administrator" },
    { "title": "Database Administrator" },
    { "title": "IT Support Specialist" },
    { "title": "Cybersecurity Analyst" },
    { "title": "Cloud Solutions Architect" },
    { "title": "Project Manager (IT)" },
    { "title": "Data Analyst" },
    { "title": "Technical Support Engineer" },
    { "title": "Business Analyst" },
    { "title": "Quality Assurance Tester" },
    { "title": "UI/UX Designer" },
    { "title": "Digital Marketing Specialist" },
    { "title": "E-commerce Manager" },
    { "title": "IT Consultant" },
    { "title": "SEO Specialist" },
    { "title": "SEO Analyst" },
    { "title": "Content Strategist" },
    { "title": "Social Media Manager" },
    { "title": "PPC Specialist" },
    { "title": "SEO Copywriter" },
    { "title": "Link Building Specialist" },
    { "title": "HR Manager" },
    { "title": "HR Specialist" },
    { "title": "Recruitment Coordinator" },
    { "title": "Talent Acquisition Manager" },
    { "title": "Employee Relations Manager" },
    { "title": "Training and Development Manager" },
    { "title": "Payroll Specialist" },
    { "title": "Compensation Analyst" },
    { "title": "HR Coordinator" },
    { "title": "HR Business Partner" },
    { "title": "Office Manager" },
    { "title": "Administrative Assistant" },
    { "title": "Executive Assistant" },
    { "title": "Personal Secretary" },
    { "title": "Receptionist" },
    { "title": "Accounts Payable Specialist" },
    { "title": "Accounts Receivable Specialist" },
    { "title": "Staff Accountant" },
    { "title": "Financial Analyst" },
    { "title": "Accounting Manager" },
    { "title": "Controller" },
    { "title": "Chief Financial Officer (CFO)" },
    { "title": "Sales Manager" },
    { "title": "Sales Executive" },
    { "title": "Account Executive" },
    { "title": "Sales Coordinator" },
    { "title": "Business Development Manager" },
    { "title": "Sales Analyst" },
    { "title": "Territory Sales Manager" },
    { "title": "Sales Representative" },
    { "title": "Project Manager" },
    { "title": "Project Coordinator" },
    { "title": "Program Manager" },
    { "title": "Project Scheduler" },
    { "title": "Project Assistant" },
    { "title": "Sports Manager" },
    { "title": "Athletic Director" },
    { "title": "Sports Marketing Manager" },
    { "title": "Sports Event Coordinator" },
    { "title": "Fitness Coordinator" },
    { "title": "Team Leader" },
    { "title": "Team Lead (Logistics)" },
    { "title": "Team Lead (Sales)" },
    { "title": "Team Lead (IT)" },
    { "title": "Team Lead (HR)" },
    { "title": "Road Coordinator" },
    { "title": "Road Team Leader" },
    { "title": "Air Team Leader" },
    { "title": "Sea Team Leader" },
    { "title": "Security Manager" },
    { "title": "Security Officer" },
    { "title": "Loss Prevention Specialist" },
    { "title": "Security Analyst" },
    { "title": "Risk Assessment Specialist" },
    { "title": "Compliance Officer" },
    { "title": "Cybersecurity Specialist" },
    { "title": "Physical Security Specialist" },
    { "title": "Security Systems Administrator" },
    { "title": "Chief Executive Officer (CEO)" },
    { "title": "President" },
    { "title": "Managing Director" },
    { "title": "Executive Director" },
    { "title": "Vice President" },
    { "title": "General Manager" },
    { "title": "Regional General Manager" },
    { "title": "Senior General Manager" },
    { "title": "Operations General Manager" },
    { "title": "General Manager (Logistics)" },
    { "title": "General Manager (Sales)" },
    { "title": "General Manager (IT)" },
    { "title": "General Manager (HR)" },
    { "title": "General Manager (Finance)" },
    { "title": "Strategic Partnerships Manager" },
    { "title": "Market Research Analyst" },
    { "title": "Business Operations Manager" },
    { "title": "Product Manager" },
    { "title": "Brand Manager" },
    { "title": "Customer Experience Manager" },
    { "title": "Corporate Development Manager" },
    { "title": "Business Intelligence Analyst" },
    { "title": "Financial Consultant" },
    { "title": "Investment Analyst" },
    { "title": "Revenue Analyst" },
    { "title": "Supply Chain Consultant" },
    { "title": "Compliance Manager" },
    { "title": "Change Management Consultant" },
    { "title": "Sales Enablement Manager" },
    { "title": "Client Relationship Manager" },
    { "title": "Business Continuity Manager" },
    { "title": "Product Marketing Manager" },
    { "title": "Analytics Manager" },
    { "title": "Legal Counsel" },
    { "title": "Market Development Manager" },
    { "title": "Analytics Manager" },
    { "title": "Sustainability Manager" }
]
