import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RoutetracingService {
  private previousUrl: string | undefined;
  private paramValue: any;
  constructor(private router: Router) {

    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        
      });
  }
  public getPreviousUrl() {
    return this.previousUrl;
  }
  public getParamValue() {
    return this.paramValue;
  }
  public setParamValue(param: any) {
    this.paramValue = param
  }
}
