<div class="banner py-5  ">
  <div class="col-12 col-xl-5 col-xxl-4 text-lg-start ms-lg-5 d-lg-flex flex-lg-column py-5 px-5 textBG">
    <h1 class="mb-5 fs-1 fw-medium" style="color:white;">We at Intercargo care about our planet</h1>
    <p class="mb-5" style="color:white;">
      We are contributing and adopting positive environmental friendly and
      target driven changes to keep our planet protected from harmful
      emissions.
    </p>
    <button class="btn btn-outline-light w-50 py-3" routerLink="/support">Contact Us</button>
  </div>
</div>

<div class="repeating-text">
  <div class="marquee">
    <div class="marquee-content">
      <ng-container *ngFor="let item of repeatElements; let i = index">
        <i class="bi bi-gear-fill text-primary pe-3"></i>
        <span class="pe-5 me-5">{{ item }}</span>
      </ng-container>
      <ng-container *ngFor="let item of repeatElements; let i = index">
        <i class="bi bi-gear-fill text-primary pe-3"></i>
        <span class="pe-5 me-5">{{ item }}</span>
      </ng-container>
    </div>
  </div>
</div>


<div class="container-fluid my-5">
  <div class="row">

    <div class="col-12 col-lg-5 mb-5">
      <img src="../../../../assets/imgs/Sustainability1.png" alt="" class="img-fluid" />
    </div>

    <div class="col-1"></div>

    <div class="col-12 col-lg-6 text-start">

      <h1 class="mb-5 fs-1 fw-light lh-base">
        This is all part of our pledge to tackle climate change, achieving
        <span style="color: #5fbf92; font-weight: 550">
          net-zero CO2 emissions </span>in 2050
      </h1>

      <p class="mb-4">
        Sustainability is at the core of our logistics processes and we are
        proud to play our role in being able to provide emission efficient
        solutions to help our customers reduce their global carbon footprint.
      </p>
      <p class="mb-4">We
        work with carriers who are using SAF (Sustainable Aviation Fuel) to
        lower the emissions omitted when operating flights in the movement of
        air freight around the world. We are able to calculate and help
        businesses offset their footprint through one of two ways. The first is
        to to use emission efficient solutions as your primary option when
        selecting our transport services and the second is to offset your carbon
        footprint by purchasing credits on your behalf to offset the calculated
        omissions currently being omitted through your transport. </p>

      <p class="mb-4">Our
        transport solutions also include route optimisation, making sure that
        your cargo uses as less transport legs as possible with the aid of
        direct flights or sailings where possible. If we can't use direct
        flights or sailings, we will look to use the most streamlined and
        quickest route possible. Another way we achieve this is by assessing
        whether your transport needs can be met by using ocean freight over air
        freight. Our teams will work with your planning teams to work out
        whether we can plan ahead of time and have your shipments arrive and
        store in bulk to cut lead times to your customers and being green at the
        same time - not to mention reducing costs versus air freight.

      </p>
      <p class="mb-4">If
        sea freight is too long for your clients, then we can look at other
        multimodal options where we use a combination of air, road and sea to
        get you the quickest solution while helping you meet your carbon
        targets. Whatever it may be, we have the solution and look forward to
        being your green forwarding partner of choice.
      </p>

    </div>
  </div>
</div>


<img src="../../../../assets/imgs/Sustainability2.png" alt="" class="img-fluid" />


<div class="container-fluid my-5">
  <div class="row">

    <div class="col-12 col-lg-6">
      <img [src]="leftImageData.img" alt="Logistics solutions" class="img-fluid" />
    </div>

    <div class="col-1"></div>

    <div class="col-12 col-lg-5 text-lg-start d-flex flex-column justify-content-center">
      <h1 [innerHTML]="leftImageData.heading" class="mb-5 lh-base fs-1 fw-medium"></h1>
      <button class="btn btn-primary" style="font-size: 16px !important" (click)="handleRoute('/support')">
        {{ leftImageData.btnTxt }}
      </button>
    </div>

  </div>
</div>