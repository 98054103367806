<div class="container py-5 px-3 bg-primary">
    <h1 class=" fs-5 text-primary text-center mb-2">
        Thank You!
    </h1>
    <h1 class=" fs-5 text-primary text-center mb-2 ">
        One of our Conexa experts will reach out to you very soon.
    </h1>

    <div
        class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center mt-2 gap-lg-0 gap-4">

        <button type="button"
            class="btn btn-outline-primary rounded-circle d-flex align-items-center justify-content-center align-self-center mx-auto my-auto"
            (click)="onCloseClick({ status: false, message: '' })">
            <i class="bi bi-check fs-3"></i>
        </button>

    </div>
</div>
