
  <div class="row">

    <div
      class="col-8 col-lg-6 order-2 order-lg-1 text-start d-flex flex-column justify-content-center align-items-center ps-lg-5 ps-5 mb-3 mb-lg-0 py-5">
      
      <div class="col-12 col-lg-6 mt-1">
        <h2>Change Password</h2>
        <br /><br />
        Choose a password that will be used every time you login. Make sure you
        remember this password.
      </div>
      <div class="col-12 col-lg-6 mt-5">
        <label for="input1">Password</label>
        <input type="password" class="form-control" />
        <br />
        <label for="input2">Confirm Password</label>
        <input type="password" class="form-control" />
      </div>
      <div class="col-12 col-lg-6 mt-xxl-5 mt-xl-3 text-lg-start text-center">
        <button class="btn btn-primary" style="width: 100% !important;">Change Password</button>
      </div>
    </div>
    <div
      class="col-12 col-lg-6 d-flex align-items-center justify-content-center order-1 order-lg-2 bg-lg-dark bg-white p-5 bg-dark">
      <div class="col-12 col-lg-8 h-100">
        <img src="../../../assets/imgs/Gif/Forget Password.gif" alt="" class="img-fluid rounded-5 w-75 " />
      </div>
    </div>

  </div>