import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class FaqServiceService {

  private apiUrlFaq = environment.zenDeskUrl + '/en-gb/articles'; // URL to your backend endpoint
  private apiUrlSec = environment.zenDeskUrl + '/sections'; // URL to your backend endpoint

  constructor(private http: HttpClient) { }

  getFaqs(): Observable<any> {
    return this.http.get<any>(this.apiUrlFaq);
  }
  getSections(): Observable<any> {
    return this.http.get<any>(this.apiUrlSec);
  }
}


