<section class="container-fluid bg-light">
  <div class="row align-items-center justify-content-between">
    <div
      class="col-lg-4 col-12 order-lg-1 order-2 align-items-lg-start text-lg-start text-md-start text-start my-5">
      <h1
        [innerHTML]="rightImageData.heading"
        class="text-lg-start text-md-start"
        style="margin-bottom: 5% !important"></h1>

      <!-- <div class="d-flex flex-column align-items-center align-items-md-start" style="margin-top: 5% !important">
        <div style="margin: 8% 0"></div>
        <span>Search FAQs</span>
        <div class="input-group d-flex align-items-center justify-content-center">
          <input type="text" class="form-control" placeholder="Type here to search" aria-label="Search"
            aria-describedby="button-addon2" (focus)="enableSearchButton()" (blur)="checkInputValue($event)" />
          <button class="btn btn-outline-secondary custom-btn" type="button" id="button-addon2"
            [class.disabled]="isDisabled">
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div> -->
    </div>

    <div class="col-lg-2 d-none d-lg-block"></div>

    <div class="col-lg-6 col-md-12 order-lg-3 order-1">
      <figure class="image-container text-center">
        <video [src]="rightImageData.img" class="img-fluid" autoplay 
        loop 
        playsinline 
        muted>
          Your browser does not support the video tag.
        </video>
      </figure>
    </div>
  </div>
</section>

<section class="container-fluid">
  <div class="row">
    <!-- <div class="col-lg-1 d-none d-lg-block"></div> -->

    <div
      class="col-lg-3 col-md-12 order-lg-1 order-2 d-flex flex-column align-items-start text-start align-items-lg-start">
      <i
        class="bi bi-arrow-left-short fs-2"
        style="cursor: pointer; display: none"
        #backIcon
        (click)="backToQuestions()"></i>
      <h1 class="text-start fs-3">Frequently Asked Questions</h1>
      <div class="faq-divider-1"></div>
      <div class="d-flex row flex-lg-column my-5 align-items-md-start w-100">
        <div
          class="input-group mb-3 d-flex align-items-center text-lg-start text-md-start w-100">
          <ul class="link-list text-primary w-100 p-0">
            <li
              (click)="getFAQs(section)"
              [class.active]="activeItem === section.name"
              *ngFor="let section of sections">
              {{ section.name }}
            </li>

          </ul>
        </div>
      </div>
    </div>

    <div class="col-lg-2 d-none d-lg-block"></div>

    <div class="col-lg-6 col-md-12 order-lg-3 order-2">
      <div #question>
        <div class="faq-container">
          <h1 class="text-primary text-start text-md-start">
            {{activeItem}}
            <div class="header-divider"></div>
          </h1>
          <div *ngFor="let question of faqQuestions; let i = index">
            <div class="faq-item pb-2" (click)="openFAQ(i)">
              <div class="faq-question">{{ question }}</div>

              <div class="faq-icon">
                <i class="bi bi-arrow-right-short"></i>
              </div>
            </div>
            <div class="faq-divider"></div>
          </div>
        </div>
      </div>

      <div #answer style="display: none">
        <h1 style="margin-top: 5%">
          {{faqQuestions[questionIndex]}}
        </h1>

        <div class="d-flex align-items-center my-5">
          <img
            src="../../../assets/imgs/faceFaq.png"
            alt="Avatar"
            class="rounded-circle avatar-img" />
          <div class="ml-3 text-start">
            <h2 class="mb-0 fw-medium">Global Operations</h2>
            <span
              class="my-0 text-primary"
              style="font-size: 12px !important; font-weight: 400">Updated -
              {{updatedDate}}</span>
          </div>
        </div>
        <div class="faq-answer-p "
          [innerHTML]="sanitizedAnswerText"></div>
      </div>
    </div>
  </div>
</section>
