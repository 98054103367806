<section class="container-fluid bg-light">
  <div class="container">
    <div class="row justify-content-lg-between">
      <div class="col-12 col-lg-5 order-lg-1 order-2 my-5">
        <div class="row">
          <h1 class="fs-2">
            You have unique experiences, skills and passions, find your Role at
            Intercargo
          </h1>
        </div>
        <!-- <div class="row mt-5 text-lg-start">
          <span class="mb-2">Search By Keywords</span>
          <div class="input-group d-flex ">
            <input type="text" class="form-control" placeholder="Type here to search Jobs" aria-label="Search"
              [formControl]="searchControl" />
            <button class="btn btn-outline-secondary" [disabled]="!searchControl.value">
              <i class="bi bi-search"></i>
            </button>
          </div>
        </div> -->
      </div>

      <div
        class="col-12 col-lg-4 order-lg-3 order-1 align-items-center d-flex justify-content-center"
      >
      <video [src]="rightImageData.img" class="img-fluid" autoplay 
      loop 
      playsinline 
      muted>
        Your browser does not support the video tag.
      </video>
      </div>
    </div>
  </div>
</section>

<section class="container my-5">
  <div class="row">
    <section #Explore class="col-lg-4 order-1">
      <div
        class="text-lg-start text-center d-flex flex-column align-items-center align-items-lg-start"
      >
        <h1 class="mb-1 mb-lg-5 fs-2">Explore Current Job Openings</h1>

        <!-- <div class="mt-5">Departments</div>
        <div class="mt-2 w-75">
          <select
            aria-label="Select a country"
            class="form-select justify-content-center"
            disabled
          >
            <option value="N/A" selected>All Departments</option>
            <option value="CA">Canada</option>
            <option value="UK">United Kingdom</option>
            <option value="AU">Australia</option>
            <option value="PK">Pakistan</option>
            <option value="IN">India</option>
            <option value="CN">China</option>
          </select>
        </div>

        <div class="mt-4">Location</div>
        <div class="mt-2 w-75">
          <select aria-label="Select a country" class="form-select" disabled>
            <option value="N/A" selected>All Locations</option>
            <option value="CA">Canada</option>
            <option value="UK">United Kingdom</option>
            <option value="AU">Australia</option>
            <option value="PK">Pakistan</option>
            <option value="IN">India</option>
            <option value="CN">China</option>
          </select>
        </div> -->
      </div>
    </section>

    <!-- <section #AllDepts class="col-lg-8 order-2 mt-5 mt-lg-0">
      <div class="row pb-3 pb-lg-1" style="border-bottom: 2px solid #ebe5e5">
        <h1 class="header-title text-primary fs-2">All Departments</h1>
      </div>

      <div
        class="row py-5"
        style="border-bottom: 2px solid #ebe5e5; cursor: pointer"
        *ngFor="let job of faqQuestions; let i = index"
        (click)="openJob(i)"
      >
        <div class="row d-lg-flex d-block justify-content-lg-between">
          <div class="col-12 col-lg-8 order-1 text-start">
            <h1 class="fs-2 text-start">{{ job.title }}</h1>
          </div>
          <div class="col-12 col-lg-4 pt-2 pt-lg-0 order-2 text-start">
            <span class="text-start">
              View Job Details <i class="bi bi-arrow-right-short"></i>
            </span>
          </div>
        </div>
        <div class="row text-lg-start mt-5 mt-lg-0 text-start">
          <span> {{ job.department }} &nbsp; &nbsp; {{ job.location }}</span>
        </div>
      </div>
    </section> -->
    <section #AllDepts class="col-lg-8 order-2 mt-5 mt-lg-0">
      <div class="row pb-3 pb-lg-1" style="border-bottom: 2px solid #ebe5e5">
        <h1 class="header-title text-primary fs-2">All Departments</h1>
      </div>

      <div
        class="row py-5"
        style="border-bottom: 2px solid #ebe5e5; cursor: pointer"
        *ngFor="let job of faqQuestions; let i = index"
        (click)="openJob(i)"
      >
        <div class="row d-lg-flex d-block justify-content-lg-between">
          <!-- On larger screens, order is Title then View Job Details -->
          <div class="col-12 col-lg-8 order-lg-1 text-start">
            <h1 class="fs-2">{{ job.title }}</h1>
          </div>
          <div class="col-12 col-lg-4 pt-2 pt-lg-0 order-lg-2 text-end">
            <span class="d-none d-lg-inline">
              View Job Details <i class="bi bi-arrow-right-short"></i>
            </span>
          </div>
        </div>
        <div class="row text-center text-lg-start mt-3 mt-lg-0">
          <span>{{ job.department }} &nbsp; &nbsp; {{ job.location }}</span>
        </div>
        <!-- On smaller screens, show View Job Details below department and location -->
        <div class="col-12 pt-2 d-lg-none mt-3">
          <span>
            View Job Details <i class="bi bi-arrow-right-short"></i>
          </span>
        </div>
      </div>
    </section>
  </div>

  <section class="container my-5">
    <div class="row">
      <section #ExploreSide class="col-lg-4 order-1">
        <div
          class="text-start text-center d-flex flex-column align-items-start align-items-lg-start"
        >
          <i
            class="bi bi-arrow-left-short align-items-center"
            style="
              font-size: 24px !important;
              font-family: Poppins;
              font-weight: 400 !important;
              cursor: pointer;
            "
            #backIcon
            (click)="back()"
          >
            <span
              style="
                font-size: 14px !important;
                font-family: Poppins;
                font-weight: 400 !important;
              "
              >Back to Job List</span
            >
          </i>

          <div
            class="text-primary"
            style="font-size: 16px; font-weight: 500; margin-top: 32%"
          >
            Department
          </div>

          <div style="font-size: 14px; font-weight: 400; margin-top: 3%">
            {{ currentJob.department }}
          </div>

          <div
            class="text-primary"
            style="font-size: 16px; font-weight: 500; margin-top: 12%"
          >
            Location
          </div>
          <div style="font-size: 14px; font-weight: 400; margin-top: 3%">
            {{ currentJob.location }}
          </div>
          <div
            style="border-bottom: 2px solid #ebe5e5"
            class="d-flex d-lg-none w-100 pt-4"
          ></div>
        </div>
      </section>

      <section #AllDeptsSide class="col-lg-8 order-2 mt-5 mt-lg-0 text-start">
        <h1 class="text-primary pb-2 pb-lg-4 text-start">
          {{ currentJob.title }}
        </h1>

        <h2 *ngIf="currentJob.description !== ''">The Role</h2>
        <p *ngIf="currentJob.description !== ''" class="p-0">
          {{ currentJob.description }}
        </p>
        <h2 *ngIf="currentJob.responsibilites !== ''">Responsiblities</h2>
        <p
          *ngIf="currentJob.responsibilites !== ''"
          class="p-0"
          [innerHTML]="currentJob.responsibilites"
        ></p>

        <h2 *ngIf="currentJob.experience !== ''">Experience</h2>
        <p
          *ngIf="currentJob.experience !== ''"
          class="p-0"
          [innerHTML]="currentJob.experience"
        ></p>

        <h2 *ngIf="currentJob.education !== '' && currentJob.education">
          Education and Qualifications/
        </h2>
        <p
          *ngIf="currentJob.education !== '' && currentJob.education"
          class="p-0"
          [innerHTML]="currentJob.education"
        ></p>

        <h2 *ngIf="currentJob.benefits !== '' && currentJob.benefits">
          Benefits
        </h2>
        <p
          *ngIf="currentJob.benefits !== '' && currentJob.benefits"
          class="p-0"
          [innerHTML]="currentJob.benefits"
        ></p>
        <div
          style="
            font-size: 14px;
            font-family: Poppins;
            font-weight: 500;
            line-height: 24px;
            word-wrap: break-word;
          "
          class="pt-3 pt-lg-5"
        >
          If this sounds like the right opportunity for you, click the below
          button and email your CV to our team and you will be contacted for a
          confidential discussion.
        </div>
        <a
          href="mailto:careers@intercargo.com?subject=Application for {{
            currentJob.title
          }}"
          class="btn btn-primary mt-5 d-flex justify-content-center align-items-center"
          >Email Your CV</a
        >
        <!-- <a class="btn btn-primary mt-5">Email Your CV</a> -->
      </section>
    </div>
  </section>
</section>
