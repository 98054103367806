import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class OnlineGuardService implements CanActivate {
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) { }

  canActivate(): Observable<boolean> {
    // Only run the check in the browser
    if (isPlatformBrowser(this.platformId)) {
      // If the browser is online, allow navigation
      if (window.navigator.onLine) {
        return of(true);
      } else {
        // If offline, redirect to the connection lost page
        this.router.navigate(['/ConnectionLost']);
        return of(false);
      }
    }

    // If it's server-side rendering (SSR), allow navigation by default
    return of(true);
  }
}
