import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-sustainability',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.css'],
})
export class SustainabilityComponent {
  leftImageData = {
    heading: `Schedule a call to learn how our platform delivers end-to-end results.`,
    description: ``,
    img: '../../../assets/imgs/Gif/Contact.gif',
    btnTxt: 'Talk to an Expert',
    btnLink: '/support',
    class: 'text-center',
  };

  repeatElements: string[] = [
    'Track your Carbon Footprint',
    'Delivering a Greener Tomorrow, Today',
    'Eco-Friendly Freight, Global Impact',
    'Efficient Routes, Sustainable Solutions',
    'Reducing Emissions, Elevating Logistics'
  ];

  constructor(private router: Router, private title: Title, private meta: Meta) {

  }

  ngOnInit(): void {
    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    this.title.setTitle(toCapitalize("Sustainability") + " - Intercargo Logistics");
     
    this.meta.removeTag('name="author"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    // this.meta.removeTag('property="og:image"');

    this.meta.addTags([
     
      { name: 'author', content: 'Intercargo Logistics' },
      { property: 'og:title', content: 'Sustainability' },
      {
        property: 'og:description', content: "We at Intercargo care about our planet." },
      // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
    ]); 

  }

  handleRoute(route: String) {
    this.router.navigate([route]);
  }
}
